<template>
  <div class="Matchcenter">
    <Header title="匹配工具" index="首页" beforeTitle="匹配工具" />
    <div class="content">
      <h1>智能匹配工具</h1>
      <h2>
        智能匹配功能，分为两种匹配类型：以公司为基准匹配政策（政策：即企业的产品/政府部门发布的最新政策）；以政策匹配企业（政策：即企业的产品/政府部门发布的最新政策；企业：公司内部数据和外网抓取数据）
      </h2>
      <h3>
        The intelligent matching function is divided into two types of matching:
        matching policies based on the company (policies: the products of the
        enterprise / the latest policies issued by government departments);
        Match the enterpri- se with policies (policies: the latest policies
        issued by the enterprise’s products / government departments;
        enterprises: data captured from the company’s internal data and external
        network)
      </h3>
      <div class="Match">
        <router-link to="/match/matchCo"
          ><img src="../../assets/images/policy.png"
        /></router-link>
        <router-link to="/match/matchBus" style="margin-left: 40px"
          ><img src="../../assets/images/company.png"
        /></router-link>
        <img src="../../assets/images/icon.png" style="margin-left: 140px" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.Matchcenter h1 {
  font-size: 20px;
  color: #000000;
}
.Matchcenter h2 {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  line-height: 20px;
  margin-top: 20px;
}
.Matchcenter h3 {
  font-size: 14px;
  color: #a6abc7;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
}
.Match {
  border: 1px solid #ebeef5;
  border-radius: 8px;
  margin-top: 20px;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>
